
import {Component, Mixins} from 'vue-property-decorator';
import {TemplateExtra} from '../module';

@Component({
  name: 'template-13'
})
export default class Template extends Mixins(TemplateExtra) {
  videoInfo = {};

  init() {
    this.data.onChange.subscribe(block => {
      const videoInfo: any = block.list || this.data.blockList || [];
      if (videoInfo[0] && videoInfo[0].block_value.url) this.videoInfo = JSON.parse(JSON.stringify(videoInfo[0].block_value))
      else this.videoInfo = {}
    });
  }
}
