
import {Component, Vue} from 'vue-property-decorator';
import {$album, AlbumImageProxy} from './services/album.service';
import AlbumImage from './album-image.vue';

@Component({
  name: 'album',
  components: {AlbumImage}
})
export default class Album extends Vue {
  display = false;
  albums = $album.albums.getValue();
  width = '836px';
  subscriptions: any[] = [];
  loading = false;
  resolveImage: (image: AlbumImageProxy) => void = null!;

  created() {
    this.subscriptions = [
      $album.albums.subscribe(albums => {
        this.albums = albums;
        this.tabSelected({index: 0});
      }),
      // $album.width.subscribe(width => {
      //   this.width = `${width}px`;
      // }),
      $album.loading.subscribe(loading => {
        this.loading = loading;
      }),
    ];
  }

  mounted() {
    $album.selectImage = () => this.selectImage();
  }

  tabSelected(tab) {
    const album = this.albums[tab.index];
    if (album && !album.images.length) {
      album.loadNext();

      const {scrollViews} = (this.$refs as any);
      const scrollView = scrollViews[tab.index];

      scrollView.addEventListener('scroll', e => {
        if (this.loading) return;

        const el = e.target;
        const scrollBottom = el.scrollHeight - el.clientHeight - el.scrollTop;

        if (scrollBottom < 30) {
          album.loadNext();
        }
      }, false);
    }
  }

  selectImage() {
    return new Promise<string>(resolve => {
      this.display = true;
      this.resolveImage = image => {
        resolve(image.url);
        this.display = false;
      }
    });
  }

  removeImage() {
  }

  destroyed() {
    this.subscriptions.map(s => s.unsubscribe());
  }
}
