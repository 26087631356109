import {Subscription} from 'rxjs';
import {SwiperOptions} from 'swiper';
import {generateKey} from './key';
import {$floor} from './floor';
import {Device} from '../components/mobile-preview/services/device';

/**
 * SwiperBoss
 * 轮播管理器
 */
export class SwiperBoss {
  private swiperList: any[] = [];
  private slideId: any;

  constructor() {
  }

  private startSlide() {
    if (this.slideId) return;

    this.slideId = setInterval(() => {
      if (this.swiperList.length === 0) {
        clearInterval(this.slideId);
        this.slideId = null;
      }
      this.swiperList.forEach((swiper, index) => {
        swiper.resize.resizeHandler();
        setTimeout(() => {
          if (swiper.activeIndex === swiper.slidesGrid.length - 1) {
            swiper.direction = 'back';
          } else if (swiper.activeIndex === 0) {
            swiper.direction = 'go';
          }
          switch (swiper.direction) {
            case 'go':
              swiper.slideNext();
              const isStopped = swiper.latestIndex === swiper.activeIndex;
              if (isStopped) swiper.direction = 'back';
              break;
            case 'back':
              swiper.slidePrev();
              break;
          }
          swiper.latestIndex = swiper.activeIndex;
        }, 175 * index);
      });
    }, 3000);
  }

  employ(swiper) {
    swiper.$key = generateKey();
    this.swiperList.push(swiper);
    this.startSlide();
    return swiper;
  }

  dismiss(swiper) {
    this.swiperList = this.swiperList.filter(s => s.$key !== swiper.$key);
  }
}

export const $swiperBoss = new SwiperBoss();

/**
 * SwiperProxy
 * 轮播代理
 */
export interface SwiperProxyOptions {
  instance: any;
  key?: any;
  refKey?: string;
  extras?: SwiperOptions;
  onReset?: () => void;
}

export class SwiperProxy {
  swiper!: any;
  subscriptions!: Subscription[];
  options!: SwiperOptions;
  onReset!: () => void;

  get ref() {
    const {
      instance,
      refKey
    } = this.config;
    return instance.$refs[refKey!];
  }

  static create(options: SwiperProxyOptions) {
    return new SwiperProxy(options);
  }

  constructor(private config: SwiperProxyOptions) {
    this.initOptions();
    this.initChangeListen();
  }

  private initOptions() {
    const self = this;
    const {extras = {}} = this.config;
    this.onReset = this.config.onReset!;
    this.onReset && this.onReset();
    this.options = {
      on: {
        init(swiper) {
          self.swiper = $swiperBoss.employ(swiper);
        },
        beforeDestroy() {
          $swiperBoss.dismiss(self.swiper);
        }
      },
      ...extras,
    };
  }

  private initChangeListen() {
    const {key} = this.config;
    this.subscriptions = [
      $floor.itemSelected.subscribe(item => {
        if (!item) return;
        if (item.key === key) {
          setTimeout(() => {
            if (!this.ref) return;

            this.ref.destroySwiper();
            this.ref.initSwiper();
          }, 500);
        }
      }),
      Device.onChange.subscribe(() => {
        if (!this.ref) return;

        this.ref.$nextTick(() => {
          this.ref.destroySwiper();
          this.ref.initSwiper();
          this.onReset && this.onReset();
        });
      }),
    ];
  }

  breakListen() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
