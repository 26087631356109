
import {Component, Vue} from 'vue-property-decorator';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {SwiperProxy} from '../../services/swiper';

@Component({
  name: 'placeholder',
  components: {
    Swiper,
    SwiperSlide,
  }
})
export default class Placeholder extends Vue {
  falseImages = new Array(3).fill(0);
  swiperProxy: SwiperProxy = null as any;

  created() {
    this.swiperProxy = SwiperProxy.create({
      instance: this,
    });
  }
}
