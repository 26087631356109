
import { Component, Mixins } from 'vue-property-decorator';
import { EditorExtra } from '../module';
import config from './$config';
import { FloorItem13Proxy } from './$proxy';
import FloorEditorFrame from '../$components/floor-editor-frame/floor-editor-frame.vue';
import OSS from 'ali-oss'
@Component({
  name: 'editor-13',
  components: {
    FloorEditorFrame,
  }
})
export default class Editor extends Mixins(EditorExtra) {
  // eslint-disable-next-line no-undef
  [x: string]: any;
  // MixinUploadApi: any;
  config = config;
  proxy: FloorItem13Proxy = null!;
  clientOSS = new OSS({
    endpoint: 'oss-cn-beijing.aliyuncs.com',
    accessKeyId: 'LTAI4FrvHJnybH5Yoyjg9TQ7',
    accessKeySecret: 'ebza9JREtEV1SKgkBYez5NN8xarnq4',
    bucket: 'sshlwap'
  });

  fileList: any = []
  videoUploader = false

  init(): any {
    console.log(this.MixinUploadApi);
    this.proxy = new FloorItem13Proxy(this.realItem);
    if (this.proxy.videoInfo.url) this.fileList = [{ name: this.proxy.videoInfo.name, url: this.proxy.videoInfo.url }]
  }

  /**
   * 文件处理
   * @param file
   * @param type
   */
  fileChange(file, type) {
    let fileType = false
    if (type === 'cover') {
      fileType = file.type.split('image').length === 2;
    } else {
      fileType = file.type.split('video').length === 2;
      this.getVideoTime(file).then(res => {
        if (fileType && res) this.putFile(file, type)
        else this.fileList = []
      })
    }

    if (!fileType && type === 'cover') this.$message.error('上传封面图只能是 JPG 或 PNG 格式!')
    else if (type === 'cover') this.putFile(file, type)
  }

  handleRemove() {
    this.proxy.videoInfo.url = ''
  }

  beforeRemove(file, fileList) {
    return this.$confirm(`确定移除 ${file.name}？`);
  }

  /** 图片上传之前的校验  */
  beforeAvatarUpload(file) {
    const isType =
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png'
    const isLt1M = file.size / 1024 / 1024 < 1

    if (!isType) {
      this.$message.error('上传封面图只能是 JPG 或 PNG 格式!')
    }
    // if (!isLt1M) {
    //   this.$message.error('上传图片大小不能超过 1MB!')
    // }
    return isType
  }

  /** 文件列表上传成功时的钩子  上传成功校验 */
  handleSuccess(response) {
    this.proxy.videoInfo.cover = response.url
  }

  /** 文件列表上传成功时的钩子  上传成功校验 */
  handleVideoSuccess(res) {
    this.proxy.videoInfo.url = res.url
    this.fileList = [{ name: this.proxy.videoInfo.name ? this.proxy.videoInfo.name : res.name, url: res.url }]
    this.$message.success('视频上传成功')
  }

  // /**
  //  * 上传文件
  //  * @param file
  //  * @param type
  //  */
  // putFile(file, type) {
  //   if (type === 'video') this.videoUploader = true
  //   const suffix = file.name.split('.')[file.name.split('.').length - 1]
  //   const filename = new Date().getTime() + '-' + Math.ceil(Math.random() * 10000) + '.' + suffix
  //   this.clientOSS.put('video/' + filename, file).then(res => {
  //     this.videoUploader = false
  //     if (res.url && type === 'cover') this.proxy.videoInfo.cover = res.url
  //     else if (res.url && type === 'video') {
  //       this.proxy.videoInfo.url = res.url
  //       this.proxy.videoInfo.name = file.name
  //       this.proxy.videoInfo.errorMsg = ''
  //       this.fileList = [{ name: file.name, url: res.url }]
  //       this.$message.success('视频上传成功')
  //     } else this.$message.error('文件上传失败')
  //   }).catch(err => {
  //     this.videoUploader = false
  //     console.log(err)
  //   })
  // }

  getVideoTime(file) {
    return new Promise((resolve, reject) => {
      const url = URL.createObjectURL(file);
      const audioElement: any = new Audio(url);
      audioElement.addEventListener("loadedmetadata", (_event) => {
        const duration = parseInt(audioElement.duration);
        // 时长
        console.log(duration);

        if (duration < 60) {
          this.proxy.videoInfo.name = file.name
          resolve(true)
        } else {
          // 超过需求限制
          this.$message.error('视频时长需在60秒以内')
          resolve(false)
        }
      });
    })
  }
}
