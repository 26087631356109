
import {Component, PropSync, Vue} from "vue-property-decorator";

  @Component({
    name: 'set-hotspot-config',
    components: {
      ExtraLink: () => import("@/combo-floor/renovation/modules/$components/floor-menu-item-editor/components/extra-link.vue")
    }
  })
export default class SetHotspotConfig extends Vue {
    @PropSync('visible') dialogVisible!: boolean;
    @PropSync('configData') realConfigData!: any;
    oldConfig={};
    realFalse=false;

    /**
     * 提交热区配置
     * @param type 是否关闭
     */
    submitHandle(type) {
      if (type === 'cancel') {
        this.$emit('cancelConfig', this.oldConfig)
      }
      this.dialogVisible = false
    }

    /**
     * 关闭热区配置前询问
     * @param done 是否关闭
     */
    handleClose(done) {
      this.$confirm('关闭配置热区，链接将自动保存，是否继续操作？')
        .then(() => {
          done();
        })
        .catch(() => {});
    }

    mounted() {
      this.oldConfig = JSON.parse(JSON.stringify(this.realConfigData))
    }
}
