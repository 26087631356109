import {FloorItem, FloorItemProxy} from '@/combo-floor/renovation/services/floor';
import {getApp} from '@/combo-floor/renovation/services/app';

export class LineProxy {
  backgroundColor = '#F4F4F4';
  height = 36;
  unit = 'px';

  constructor(data: any) {
    if (data) {
      this.backgroundColor = data.block_value;

      if (data.block_opt) {
        const height = parseInt(data.block_opt.height);
        this.setHeight(height);
        // this.unit = data.block_opt.height.replace(height, '');
      }
    }
  }

  setHeight(height: number) {
    // if (height < 10) height = 10;
    this.height = height;
  }

  getHeight() {
    return `${this.height}${this.unit}`;
  }

  toJson() {
    return {
      block_type: 'LINE',
      block_value: this.backgroundColor.toUpperCase(),
      block_opt: {
        height: this.getHeight(),
      }
    }
  }
}

export class FloorItem8Proxy extends FloorItemProxy {
  line!: LineProxy;

  constructor(item: FloorItem) {
    super(item);
    // 注册检测是否改变的函数
    item.isChange = () => this.isChange();
  }

  parse(item: FloorItem) {
    this.line = new LineProxy(item.blockList[0]);
  }

  hasError() {
    const error = !this.line.height || this.line.height < 1;
    if (error) getApp().$message.error('请填写正确的高度！（高度最低为1像素）');
    else getApp().$message.success('模块数据保存成功');
    return error;
  }

  toJson() {
    return [this.line.toJson()];
  }
}
