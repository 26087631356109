import {Tween, update, Easing} from '@tweenjs/tween.js';

export class Scroller {
  info = {
    x: 0,
    y: 0
  };

  constructor(private el: HTMLElement) {
  }

  private animate(newInfo: {x?: number, y?: number}) {
    this.info = {
      x: this.el.scrollLeft,
      y: this.el.scrollTop,
    };
    new Tween<any>(this.info)
      .to(newInfo)
      .duration(300)
      .easing(Easing.Cubic.InOut)
      .onUpdate(() => {
        this.el.scrollTop = this.info.y;
        this.el.scrollLeft = this.info.x;
      })
      .start();
  }

  toTop(y: number) {
    this.animate({y});
  }

  toLeft(x: number) {
    this.animate({x});
  }
}

export class ScrollerFactory {
  constructor() {
    function animate(time: number) {
      try {
        update(time);
        setTimeout(() => {
          requestAnimationFrame(animate)
        }, 1000 / 60);
      } catch (e) {
      }
    }

    animate(0);
  }

  create(el: HTMLElement) {
    return new Scroller(el);
  }
}

export const $scroller = new ScrollerFactory();
