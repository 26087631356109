
import {Component, Mixins} from 'vue-property-decorator';
import {TemplateExtra} from '../module';
import Placeholder from './placeholder.vue';
import {ImageProxy} from './$proxy';

@Component({
  name: 'template-7',
  components: {
    Placeholder
  },
})
export default class Template extends Mixins(TemplateExtra) {
  image: ImageProxy = null!;

  init() {
    this.data.onChange.subscribe(block => {
      const list = block.list || this.data.blockList || [];
      this.image = new ImageProxy(list[0])
    });
  }
}
