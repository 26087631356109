
import {Component, Mixins} from 'vue-property-decorator';
import {TemplateExtra} from '../module';
import {FloorMenuItem} from '../../services/floor-menu-item';

@Component({
  name: 'template-2',
})
export default class Template extends Mixins(TemplateExtra) {
  length = 5;
  list: FloorMenuItem[] = [];

  init() {
    this.data.onChange.subscribe(block => {
      const list = block.list || this.data.blockList || [];
      this.list = Array.from({length: this.length}).map((_, index) => {
        const item = list[index];
        return FloorMenuItem.createImage(item);
      });
    });
  }
}
