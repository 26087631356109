
import {Component, PropSync, Vue} from 'vue-property-decorator';
import {BackgroundRenderOptions} from '@/combo-floor/renovation/modules/module.config';

@Component({
  name: 'background-render',
})
export default class BackgroundRender extends Vue {
  @PropSync('src', {required: true}) realSrc!: string;
  @PropSync('options', {required: true}) realOptions!: BackgroundRenderOptions;
}
