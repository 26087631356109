
import {Component, PropSync, Vue} from 'vue-property-decorator';
import {Subject} from 'rxjs';
import draggable from 'vuedraggable';
import GoodsCard from '../gift-card/gift-card.vue';
import {$goods, GoodsProxy} from '@/combo-floor/renovation/services/goods';
import FloorEditorFrame from '../floor-editor-frame/floor-editor-frame.vue';

@Component({
  name: 'goods-editor',
  components: {
    FloorEditorFrame,
    GoodsCard,
    draggable,
  }
})
export default class GoodsEditor extends Vue {
  @PropSync('title', {default: ''}) realTitle!: string;
  @PropSync('disableAction', {default: false}) realDisableAction!: boolean;
  @PropSync('onSave') realOnSave!: Subject<any>;
  @PropSync('onReset') realOnReset!: Subject<any>;
  @PropSync('onRemove') realOnRemove!: Subject<any>;

  @PropSync('goodsList', {
    required: true,
    default: () => []
  }) realGoodsList!: GoodsProxy[];

  sorts = [
    {
      icon: 'el-icon-sort-up',
      label: '升序',
      command: 'up',
    },
    {
      icon: 'el-icon-sort-down',
      label: '降序',
      command: 'down',
    },
    {
      icon: 'el-icon-sort',
      label: '无序',
      command: '',
      hidden: true,
    },
  ];

  page_no = 0;
  page_size = 20;

  mounted() {
    console.log(this.realGoodsList, 777)
  }

  currentSort = this.sorts[2];

  selectSort(sort = this.sorts[2]) {
    this.currentSort = sort;
    console.log(this.currentSort)
    let sortFn: (g1: GoodsProxy, g2: GoodsProxy) => number = null!;
    console.log(sortFn)
    if (sort.command === 'up') {
      sortFn = (g1, g2) => g1.marketPrice - g2.marketPrice;
    } else if (sort.command === 'down') {
      sortFn = (g1, g2) => g2.marketPrice - g1.marketPrice;
    }

    if (sortFn) {
      this.realGoodsList = this.realGoodsList.sort(sortFn);
      this.realGoodsList = this.realGoodsList.sort(sortFn);
    }
    console.log(123)
    console.log(this.realGoodsList)
    this.$emit('sync');
  }

  goodsResorted() {
    this.currentSort = this.sorts[2];
    this.$emit('sync');
  }

  selectGoods() {
    console.log(this.realGoodsList)
    $goods.selectGoods(true, [...this.realGoodsList].reverse()).then(resp => {
      this.realGoodsList = resp.reverse();
      this.realGoodsList = resp.reverse();
      this.$emit('sync');
    });
  }

  moveGoodsToTop(index = 0, disabled = false) {
    if (disabled) return;

    const goods = this.realGoodsList[index];
    const list = this.realGoodsList.filter((_, i) => i !== index);
    list.unshift(goods);
    this.realGoodsList = list;

    // const goods = this.realGoodsList.splice(index, 1)[0];
    // this.realGoodsList.unshift(goods);

    this.$emit('sync');
  }

  moveGoodsToPrev(index = 0, disabled = false) {
    if (disabled) return;

    // const goods = this.realGoodsList.splice(index, 1)[0];
    // this.realGoodsList.splice(index - 1, 0, goods);

    const goods = this.realGoodsList[index];
    const list = this.realGoodsList.filter((_, i) => i !== index);
    list.splice(index - 1, 0, goods);
    this.realGoodsList = list;

    this.$emit('sync');
  }

  removeGoods(index) {
    this.realGoodsList.splice(index, 1);
    this.$emit('sync');
  }

  moveGoodsToNext(index = 0, disabled = false) {
    if (disabled) return;

    // const goods = this.realGoodsList.splice(index, 1)[0];
    // this.realGoodsList.splice(index + 1, 0, goods);

    const goods = this.realGoodsList[index];
    const list = this.realGoodsList.filter((_, i) => i !== index);
    list.splice(index + 1, 0, goods);
    this.realGoodsList = list;

    this.$emit('sync');
  }

  moveGoodsToBottom(index = 0, disabled = false) {
    if (disabled) return;

    // const goods = this.realGoodsList.splice(index, 1)[0];
    // this.realGoodsList.push(goods);

    const goods = this.realGoodsList[index];
    const list = this.realGoodsList.filter((_, i) => i !== index);
    list.push(goods);
    this.realGoodsList = list;

    this.$emit('sync');
  }
}
