
import {Component, PropSync, Vue} from 'vue-property-decorator';
import draggable from 'vuedraggable';
import {$floorModules} from '@/combo-floor/renovation/modules';
import {FloorModule} from '../../modules/module.config';
import {Floor, FloorItem} from '@/combo-floor/renovation/services/floor';
import BackgroundRender from '../background-render/background-render.vue';

@Component({
  name: 'floor-module-panel',
  components: {
    BackgroundRender,
    draggable,
  },
})
export default class FloorModulePanel extends Vue {
  @PropSync('floor') realFloor!: Floor;
  modules:any = [];

  draggableOptions = {
    sort: false,
    group: {
      name: 'floor',
      pull: 'clone',
      put: false
    },
  };

  clone(item: FloorModule) {
    return FloorItem.create(item, true);
  }

  created() {
    const FM = $floorModules.modules
    if (this.$route.path === '/combo-floor/common-page') {
      FM.forEach(f => {
        if (f.type === 1 || f.type === 4 || f.type === 7 || f.type === 10 || f.type === 13) this.modules.push(f)
      })
      $floorModules.modules = this.modules
    } else this.modules = FM
  }

  mounted() {
    const unwatch = this.$watch('isReady', function (newValue) {
      console.log(newValue);
      unwatch()
    });
  }
}
