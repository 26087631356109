
import {Component, Mixins} from 'vue-property-decorator';
import {EditorExtra} from '../module';
import config from './$config';
import {FloorItem7Proxy} from './$proxy';
import FloorEditorFrame from '../$components/floor-editor-frame/floor-editor-frame.vue';
import FloorMenuItemEditor from '../$components/floor-menu-item-editor/floor-menu-item-editor.vue';

@Component({
  name: 'editor-7',
  components: {
    FloorMenuItemEditor,
    FloorEditorFrame,
  }
})
export default class Editor extends Mixins(EditorExtra) {
  config = config;
  proxy: FloorItem7Proxy = null!;

  init(): any {
    this.proxy = new FloorItem7Proxy(this.realItem);
  }
}
