
import {Component, Mixins, Watch} from 'vue-property-decorator';
import {TemplateExtra} from '../module';

@Component({
  name: 'template-12'
})
export default class Template extends Mixins(TemplateExtra) {
  rubikCube = null;
  boxWidth = 0
  boxHeight = 0

  /**
   * 魔方数据回显
   * @param newValue
   */
  @Watch('rubikCube', {deep: true})
  watchRubikCube(newValue) {
    this.$nextTick(() => {
      const boxDom:any = document.querySelector('.rubik-cube-show')
      if (boxDom) {
        this.boxWidth = boxDom.clientWidth
        this.boxHeight = this.boxWidth / newValue.column * newValue.row
        newValue.dataList.forEach(data => {
          const dom:any = document.querySelector('.show-' + data.createTime)
          const cellWidth = (this.boxWidth - newValue.gapValue) / newValue.column
          const cellHeight = (this.boxHeight - newValue.gapValue) / newValue.row
          const row = data.end.row >= data.start.row ? data.start.row : data.end.row
          const column = data.end.column >= data.start.column ? data.start.column : data.end.column
          const rowNum = Math.abs(data.end.row - data.start.row) + 1
          const columnNum = Math.abs(data.end.column - data.start.column) + 1
          const realWidth = cellWidth * columnNum - newValue.gapValue
          const realHeight = cellHeight * rowNum - newValue.gapValue
          dom.style.width = realWidth > 5 ? realWidth + 'px' : '5px'
          dom.style.height = realHeight > 2 ? realHeight + 'px' : '2px'
          dom.style.top = cellHeight * (row - 1) + newValue.gapValue + 'px'
          dom.style.left = cellWidth * (column - 1) + newValue.gapValue + 'px'
          dom.style.backgroundImage = `url(${data.imgUrl})`
        })
      }
    })
  }

  init() {
    this.data.onChange.subscribe(block => {
      const data:any = block.list || this.data.blockList || [];
      if (data[0]) this.rubikCube = JSON.parse(JSON.stringify(data[0].block_value))[0]
      else this.rubikCube = null
    });
  }
}
