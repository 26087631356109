
import {Component, Mixins} from 'vue-property-decorator';
import {EditorExtra} from '../module';
import config from './$config';
import {FloorItem8Proxy} from './$proxy';
import FloorEditorFrame from '../$components/floor-editor-frame/floor-editor-frame.vue';

@Component({
  name: 'editor-8',
  components: {
    FloorEditorFrame,
  }
})
export default class Editor extends Mixins(EditorExtra) {
  config = config;
  proxy: FloorItem8Proxy = null!;

  init(): any {
    this.proxy = new FloorItem8Proxy(this.realItem);
  }

  resetHeight(e: string) {
    if (!e) return;
    let height = parseFloat(e);
    if (isNaN(height)) {
      height = parseFloat(e.toString().match(/^[+-]?(0|([1-9]\d*))(\.\d+)?$/g)![0]);
    }
    this.proxy.line.height = height;
  }
}
