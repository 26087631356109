import {Component, Prop, PropSync, Vue, Watch} from 'vue-property-decorator';
import {Subject} from 'rxjs';
import {Floor, FloorItem} from '@/combo-floor/renovation/services/floor';
// import FloorEditorFrame from './$components/floor-editor-frame/floor-editor-frame.vue';

class TemplateContainer {
  onClick = new Subject();

  get clientHeight() {
    return this.ref.clientHeight;
  }

  get clientWidth() {
    return this.ref.clientWidth;
  }

  constructor(public ref: HTMLDivElement) {
  }

  toggleClass(cls: string, toggle: boolean = undefined!) {
    const classList = this.ref.classList;
    const contains = classList.contains(cls);
    if (toggle === undefined) {
      contains ? classList.remove(cls) : classList.add(cls);
    } else {
      toggle ? (!contains && classList.add(cls)) : (contains && classList.remove(cls));
    }
  }

  setEditMode(editMode: boolean) {
    this.toggleClass('edit-mode', editMode);
  }

  setEditing(editing: boolean) {
    this.toggleClass('is-editing', editing);
  }

  initEvents() {
    this.ref.addEventListener('click', e => this.onClick.next(e), false);
  }
}

@Component
export class TemplateExtra extends Vue {
  @Prop({ default: null }) data!: FloorItem;
  @Prop({ default: false }) editMode!: boolean;

  container: TemplateContainer = null!;

  get ref() {
    if (!this.container) this.container = new TemplateContainer(this.$el as any);
    return this.container;
  }

  mounted() {
    if (!this.data) throw new Error('必须传入data');
    /**
     * 将模板元素注入到楼层数据中
     */
    this.data.templateEl = this.$el as any;
    /**
     * 初始化容器事件
     */
    this.ref.initEvents();
    /**
     * 设置编辑模式
     */
    this.ref.setEditMode(this.editMode);
    /**
     * 注册点击事件回调
     */
    this.ref.onClick.subscribe(() => {
      /**
       * 当点击发生时，编辑该楼层数据
       */
      this.data.edit();
    });
    /**
     * 注册编辑事件回调
     */
    this.data.isEditing.subscribe(editing => {
      /**
       * 如果编辑了，则改变当前编辑状态
       */
      this.ref.setEditing(editing);
    });

    /**
     * 调用初始化函数
     */
    setTimeout(() => this.init && this.init());
  }

  init?(): any;
}

@Component
export class EditorExtra extends Vue {
  @PropSync('item', { default: null }) realItem!: FloorItem;
  @PropSync('disableAction', { default: false }) realDisableAction!: boolean;
  @PropSync('floor') realFloor!: Floor;

  get frame() {
    return this.$refs.frame; // as FloorEditorFrame
  }

  get subtitle() {
    return this.realItem ? this.realItem.keyText : 'Loading...';
  }

  mounted() {
    this.floorItemChanged();
  }

  @Watch('floorItem', { immediate: true })
  floorItemChanged() {
    if (this.realItem) {
      this.init && this.init();
    }
  }

  init?(): any;
}
