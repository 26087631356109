import {FloorItem, FloorItemProxy} from '@/combo-floor/renovation/services/floor';
import {Action} from '@/combo-floor/renovation/services/action';
import {generateKey} from '@/combo-floor/renovation/services/key';
import {FloorMenuItem} from '@/combo-floor/renovation/services/floor-menu-item';

export class ImageProxy {
  key = generateKey();
  url = '';
  action!: Action;
  el!: HTMLImageElement;

  constructor(data: any) {
    if (data) {
      this.url = data.block_value;
      this.action = Action.create(data.block_opt);
    } else {
      this.action = Action.create();
    }
  }

  setEl(el: HTMLImageElement) {
    this.el = el;
  }
}

export class FloorItem7Proxy extends FloorItemProxy {
  image!: FloorMenuItem;

  constructor(item: FloorItem) {
    super(item);
    // 注册检测是否改变的函数
    item.isChange = () => this.isChange();
  }

  parse(item: FloorItem) {
    this.image = FloorMenuItem.createImage(item.blockList[0]);
  }

  hasError() {
    return this.image.hasError();
  }

  toJson() {
    return [this.image.toImageJson()];
  }

  clear(vm) {
    this.image = FloorMenuItem.createImage(null);
    vm.$forceUpdate();
  }
}
