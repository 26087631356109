
import {Component, Mixins} from 'vue-property-decorator';
import {TemplateExtra} from '../module';
import {GoodsProxy} from '@/combo-floor/renovation/services/goods';
import GoodsCard from '../$components/gift-card/gift-card.vue';
import Placeholder from './placeholder.vue';

@Component({
  name: 'template-6',
  components: {
    GoodsCard,
    Placeholder
  }
})
export default class Template extends Mixins(TemplateExtra) {
  goodsList: GoodsProxy[] = [];
  frontData: GoodsProxy[] = [];
  frontPageSize = 20;
  frontPageIndex = 0;
  frontHasNextPage = true;

  init() {
    this.data.onChange.subscribe(block => {
      let list = block.list || this.data.blockList || [];

      const first = list[0];
      if (first?.block_type === 'GOODSLIST') {
        list = first.block_value.reduce((map, goods) => {
          if (!map[goods.goods_id]) {
            map[goods.goods_id] = true;
            map.array.push(goods);
          }
          return map;
        }, {array: []}).array;
      }

      this.goodsList = list.map(g => GoodsProxy.create(g));
      console.log(this.goodsList)
      this.resetPage();
      this.loadNextPage();
    });
  }

  resetPage() {
    this.frontHasNextPage = true;
    this.frontPageIndex = 0;
    this.frontData = [];
  }

  loadNextPage() {
    if (!this.frontHasNextPage) return;
    if (this.goodsList.length === 0) return;

    const startIndex = this.frontPageIndex * this.frontPageSize;
    let endIndex = (this.frontPageIndex + 1) * this.frontPageSize;
    if (endIndex > this.goodsList.length) {
      endIndex = this.goodsList.length;
      this.frontHasNextPage = false;
    }

    this.frontData = [...this.frontData, ...this.goodsList.slice(startIndex, endIndex)];
    this.frontPageIndex++;
  }
}
