
import {Component, Vue} from 'vue-property-decorator';
import {$goods, GoodsProxy} from '@/combo-floor/renovation/services/goods';

@Component({
  name: 'goods-selector-old'
})
export default class GoodsSelectorOld extends Vue {
  display = false;
  multiple = false;
  resolveGoods: any = null;
  selectedGoodsList: any[] = [];

  close() {
    this.display = false;
  }

  created() {
    $goods.selectGoods = (multiple, selectedGoods) => this.selectGoods(multiple, selectedGoods?.map(g => g.originalData));
  }

  selectGoods(multiple?: boolean, selectedGoodsList: any[] = []) {
    return new Promise<any>(resolve => {
      this.multiple = !!multiple;
      this.selectedGoodsList = selectedGoodsList;
      this.display = true;

      this.resolveGoods = (goods: any[]) => {
        const ids: any[] = [];
        goods = goods.filter(g => {
          if (ids.includes(g.goods_id)) return false;
          ids.push(g.goods_id);
          return true;
        });

        resolve(goods.map(g => new GoodsProxy(g)));
        this.display = false;
      };
    })
  }

  select(item: any) {
    this.resolveGoods([item]);
  }

  selectAll(list: any[]) {
    this.resolveGoods(list);
  }
}
