
import {Component, Mixins} from 'vue-property-decorator';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {TemplateExtra} from '../module';
import {GoodsProxy} from '../../services/goods';
import {SwiperProxy} from '../../services/swiper';
import GoodsCard from '../$components/gift-card/gift-card.vue';
import Placeholder from './placeholder.vue';

@Component({
  name: 'template-5',
  components: {
    Swiper,
    SwiperSlide,
    GoodsCard,
    Placeholder
  }
})
export default class Template extends Mixins(TemplateExtra) {
  goodsList: GoodsProxy[] = [];
  swiperProxy: SwiperProxy = null!;

  get goodsListTop10() {
    if (this.goodsList.length > 10) {
      return this.goodsList.slice(0, 10);
    } else {
      return this.goodsList;
    }
  }

  init() {
    this.swiperProxy = SwiperProxy.create({
      instance: this,
      key: this.data.key,
      refKey: 'swiper',
      extras: {
        slidesPerView: 'auto',
        spaceBetween: 0,
      }
    });
    this.data.onChange.subscribe(block => {
      let list = block.list || this.data.blockList || [];

      const first = list[0];
      if (first?.block_type === 'GOODSLIST') {
        list = first.block_value || [];
      }

      this.goodsList = list.map(g => GoodsProxy.create(g));
    });
  }
}
