import {FloorItem, FloorItemProxy} from '@/combo-floor/renovation/services/floor';

export class FloorItem9Proxy extends FloorItemProxy {
  elevatorDataList:any;
  oldData:any;

  constructor(item: FloorItem) {
    super(item);
    // 注册检测是否改变的函数
    item.isChange = () => this.isChange();
  }

  parse(item: FloorItem) {
    const block = item.blockList[0];
    if (block) {
      this.elevatorDataList = JSON.parse(JSON.stringify(block.block_value))
      this.oldData = JSON.parse(JSON.stringify(block.block_value))
    } else {
      this.elevatorDataList = [{
        titleBack: '#FFFFFF',
        subSwitch: false,
        arrangementMode: '0',
        floorData: []
      }];
    }
  }

  hasError() {
    return false;
  }

  toJson() {
    return [
      {
        block_type: 'ELEVATOR',
        block_value: this.elevatorDataList,
      }
    ];
  }

  toOldJson() {
    return [
      {
        block_type: 'ELEVATOR',
        block_value: this.oldData,
      }
    ];
  }
}
