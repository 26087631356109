
import {Component, PropSync, Vue} from 'vue-property-decorator';
import {$album} from '@/combo-floor/renovation/components/album/services/album.service';

@Component({
  name: 'extra-cover',
})
export default class ExtraCover extends Vue {
  @PropSync('src', {
    default: '',
    required: true
  }) realSrc!: string;

  @PropSync('width', {
    default: 0
  }) realWidth!: number;

  @PropSync('height', {
    default: 0
  }) realHeight!: number;

  @PropSync('maxSize', {
    default: 5
  }) realMaxSize!: number;

  imageWidth = 0;
  imageHeight = 137;
  suggestAuto = false;

  get style() {
    return {
      width: `${this.imageWidth * 0.7}px`,
      height: `${this.imageHeight * 0.7}px`,
    };
  }

  mounted() {
    let width = this.realWidth;
    let height = this.realHeight;

    if (this.realWidth === 0 && this.realHeight === 0) {
      this.suggestAuto = true;
      width = 750;
      height = 500;
    }

    this.imageWidth = width / height * this.imageHeight;
  }

  viewImages() {
    $album.viewImages([this.realSrc]);
  }

  selectImage() {
    $album.selectImage().then(url => {
      this.realSrc = url;
    });
  }
}
