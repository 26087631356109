
import {Component, PropSync, Vue, Watch} from 'vue-property-decorator';
import {Subscription} from 'rxjs';
import {$floor, Floor, FloorItem} from '../../services/floor';
import {generateKey} from '../../services/key';

const $top = -10;

  @Component({
    name: 'floor-module-editor'
  })
export default class FloorModuleEditor extends Vue {
    @PropSync('floor') realFloor!: Floor;
    @PropSync('disableAction', {default: false}) realDisableAction!: boolean;

    editor: any = null!;
    floorItem: FloorItem = null!;
    subscriptions: Subscription[] = [];
    marginTop = $top;

    mounted() {
      let maxHeight = 0;
      this.subscriptions = [
        /**
         * 注册楼层被删除事件回调
         */
        $floor.itemRemoved.subscribe(() => {
          /**
           * 如果楼层数据被清空了，把编辑器置空
           */
          if (this.realFloor?.data.length === 0) {
            this.editor = null;
          }
        }),
        /**
         * 注册楼层被选择事件回调
         */
        $floor.itemSelected.subscribe(selected => {
          /**
           * 如果被选中的楼层为空
           */
          if (!selected) {
            /**
             * 则将编辑器置空，然后直接返回
             */
            this.editor = null;
            return;
          }

          /**
           * 先将旧的数据置空
           */
          this.floorItem = null!;

          this.$nextTick(() => {
            /**
             * 取出最新的高度
             */
            const marginTop = $floor.marginTopChanged.getValue() + $top;
            /**
             * 将高度注入到当前高度
             */
            this.marginTop = marginTop;
            /**
             * 重新生成key
             */
            selected.editor._key = generateKey();
            /**
             * 将编辑器替换至新的编辑器
             */
            $floor.setBackground.next('close')
            this.editor = selected.editor;
            /**
             * 将楼层数据同步
             */
            this.floorItem = selected;

            setTimeout(() => {
              if (marginTop <= 40) {
                $floor.scrollTo(0);
              } else {
                $floor.scrollTo(marginTop);
              }

              if (this.$el.children) {
                const containerHeight = this.$el.children[0].clientHeight + marginTop;

                if (maxHeight < containerHeight) {
                  maxHeight = containerHeight;
                  this.$el.parentElement!.style.height = `${maxHeight}px`;
                }
              }
            }, 375);
          });
        }),
        /**
         * 设置背景色时置空编辑器
         */
        $floor.setBackground.subscribe(value => {
          if (value === 'setBack') this.editor = null;
        }),
      ];
    }

    destroyed() {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
}
