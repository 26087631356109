
import {Component, Mixins} from 'vue-property-decorator';
import draggable from 'vuedraggable';
import config from './$config';
import {EditorExtra} from '../module';
import {FloorItem2Proxy} from './$proxy';
import FloorEditorFrame from '../$components/floor-editor-frame/floor-editor-frame.vue';
import FloorMenuItemEditor from '../$components/floor-menu-item-editor/floor-menu-item-editor.vue';

@Component({
  name: 'editor-2',
  components: {
    FloorMenuItemEditor,
    FloorEditorFrame,
    draggable,
  }
})
export default class Editor extends Mixins(EditorExtra) {
  config = config;
  proxy: FloorItem2Proxy = null!;

  init(): any {
    this.proxy = new FloorItem2Proxy(this.realItem);
  }
}
