
import {Component, PropSync, Vue, Watch} from 'vue-property-decorator';
import {Action, ActionTypes} from '@/combo-floor/renovation/services/action';
import {$goods, GoodsCategory, GoodsProxy} from '@/combo-floor/renovation/services/goods';
import {$floorExtra} from '@/combo-floor/renovation/services/floor.extra';
import {Floor} from '@/combo-floor/renovation/services/floor';
import GoodsCard from '@/combo-floor/renovation/modules/$components/gift-card/gift-card.vue';
import SetHotspot from '@/combo-floor/renovation/modules/$components/floor-menu-item-editor/components/set-hotspot.vue'

let $goodsCategories: GoodsCategory[] = [];
let $goodsSpecialSubjects: Floor[] = [];
let $childFloors: Floor[] = [];
let $isInit = false;

  @Component({
    name: 'extra-link',
    components: {GoodsCard, SetHotspot}
  })
export default class ExtraOperate extends Vue {
    @PropSync('title', {default: '链接'}) realTitle!: string;
    @PropSync('type') realType!: ActionTypes;
    @PropSync('value') realValue!: any;
    @PropSync('desc') realDesc!: string;
    @PropSync('extraData') realExtraData!: any;
    @PropSync('goodsPreviewBottom') realGoodsPreviewBottom!: boolean;
    @PropSync('errorText') realErrorText!: string;
    @PropSync('setHot', {default: true}) realSetHot!: boolean;
    @PropSync('src') realImgSrc!: any;
    @PropSync('floor') realFloor!: Floor;

    opts = JSON.parse(JSON.stringify(Action.all));
    goods: GoodsProxy = null!;
    goodsCategories = $goodsCategories;
    goodsSpecialSubjects = $goodsSpecialSubjects;
    childFloors = $childFloors;

    created() {
      if (!$isInit) {
        $isInit = true;
        this.$router.afterEach(() => {
          // 在页面切走的时候置空，可以保证下次进页面的时候数据是最新的
          $goodsCategories = [];
          $goodsSpecialSubjects = [];
          $childFloors = [];
        });
      }
    }

    mounted() {
      // switch (this.realType) {
      //   case 'GOODS':
      //     if (this.realExtraData) {
      //       this.goods = new GoodsProxy(this.realExtraData);
      //       if (this.goods.isEmpty) {
      //         this.realExtraData = undefined;
      //         this.realType = 'NONE';
      //       }
      //     }
      // }
    }

    select() {
      // switch (this.realType) {
      //   case 'GOODS':
      //     return $goods.selectGoods().then(([goods]) => {
      //       this.realDesc = goods.name;
      //       this.realValue = goods.id;
      //       this.realExtraData = goods.toJson();
      //       this.goods = goods;
      //       this.emitRecheck();
      //     });
      // }
    }

    emitRecheck() {
      this.$nextTick(() => {
        this.$emit('recheck');
      });
    }

    clearSurplusInfo() {
      this.realValue = '';
      this.realDesc = '';
      this.realExtraData = {};
      this.realErrorText = '';
      this.goods = null!;
      this.emitRecheck();
    }
}
