
import {Component, PropSync, Vue} from 'vue-property-decorator';

@Component({
  name: 'search-box'
})
export default class SearchBox extends Vue {
  @PropSync('isEditing') realIsEditing!: boolean;

  searchText = '';
}
