
import {Component, PropSync, Vue} from 'vue-property-decorator';
import {$album, AlbumImageProxy} from './services/album.service';
import {ElLoadingComponent} from 'element-ui/types/loading';

@Component({
  name: 'album-image'
})
export default class AlbumImage extends Vue {
  @PropSync('image', {required: true}) realImage!: AlbumImageProxy;

  uploadUrl = $album.uploadUrl;
  uploadAccept = $album.uploadAccept;

  files: any[] = [];
  uploadedFiles: any[] = [];
  loadingInstance: ElLoadingComponent = null!;

  viewImage() {
    $album.viewImages([this.realImage.url]);
  }

  removeImage() {
    this.$emit('remove', this.realImage);
  }

  selectImage() {
    this.$emit('select', this.realImage);
  }

  beforeUpload(file) {
    if (!file.type.includes('image')) {
      this.$message.error('只能上传图片！');
      return false;
    }

    const isLt5M = file.size / 1024 / 1024 < 5;

    if (!isLt5M) {
      this.$message.error('图片大小不能超过 5MB!');
    } else {
      this.loadingInstance = this.$loading({
        text: '上传中',
      });
    }
    return isLt5M;
  }

  handleUploadSuccess(response: any, file: any, fileList: any[]) {
    this.uploadedFiles.push(response);

    if (this.uploadedFiles.length === fileList.length) {
      this.realImage.upload(this.uploadedFiles);
      this.uploadedFiles = [];
      this.files = [];
      this.loadingInstance.close();
    }
  }
}
