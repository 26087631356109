
import { Component, Mixins } from "vue-property-decorator";
import { TemplateExtra } from "../module";

@Component({
  name: "template-0"
})
export default class Template extends Mixins(TemplateExtra) {

}
