import { FloorItem, FloorItemProxy } from '@/combo-floor/renovation/services/floor';

export class FloorItem13Proxy extends FloorItemProxy {
  videoInfo;

  constructor (item: FloorItem) {
    super(item);
    // 注册检测是否改变的函数
    item.isChange = () => this.isChange();
  }

  parse (item: FloorItem) {
    const block = item.blockList[0];
    if (block && block.block_value) {
      this.videoInfo = JSON.parse(JSON.stringify(block.block_value))
    } else {
      this.videoInfo = {
        url: '',
        name: '',
        cover: '',
        remark: '',
        errorMsg: ''
      };
    }
  }

  hasError () {
    if (this.videoInfo.url) {
      this.videoInfo.errorMsg = ''
      return false
    } else {
      this.videoInfo.errorMsg = '视频不能为空！'
      return true
    }
  }

  toJson () {
    return [
      {
        block_type: 'VideoInfo',
        block_value: this.videoInfo,
      }
    ];
  }
}
