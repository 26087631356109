
import {Component, Mixins} from 'vue-property-decorator';
import {TemplateExtra} from '../module';
import {ImageProxy} from '../7/$proxy';

@Component({
  name: 'template-3'
})
export default class Template extends Mixins(TemplateExtra) {
  images: ImageProxy[] = [];
  minHeight = 90;
  watcher = null;

  init() {
    this.data.onChange.subscribe(block => {
      const list = block.list || this.data.blockList || [];
      this.images = [0, 1].map(index => {
        return new ImageProxy(list[index]);
      });
    });
  }

  reloadMinHeight() {
    this.minHeight = Math.min(...this.images.map(image => image.el ? image.el.clientHeight : 100));
  }

  imageLoaded(index: number, e) {
    const el = e.target || e.path[0];
    this.images[index].setEl(el);
    this.reloadMinHeight();
  }
}
