
import { Component, Mixins } from 'vue-property-decorator';
import { EditorExtra } from '../module';
import config from './$config';
import { FloorItem4Proxy } from './$proxy';
import FloorEditorFrame from '../$components/floor-editor-frame/floor-editor-frame.vue';
import FloorMenuItemEditor from '../$components/floor-menu-item-editor/floor-menu-item-editor.vue';

@Component({
  name: 'editor-4',
  components: {
    FloorMenuItemEditor,
    FloorEditorFrame,
  }
})
export default class Editor extends Mixins(EditorExtra) {
  config = config;
  proxy: FloorItem4Proxy = null!;

  init(): any {
    this.proxy = new FloorItem4Proxy(this.realItem);
  }
}
