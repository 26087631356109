
import {Component, PropSync, Vue} from 'vue-property-decorator';

@Component({
  name: 'extra-suggest'
})
export default class ExtraSuggest extends Vue {
  @PropSync('removeText') realRemoveText!: string;
  @PropSync('width', {default: 0}) realSuggestWidth!: number;
  @PropSync('height', {default: 0}) realSuggestHeight!: number;
  @PropSync('size', {default: 5}) realSuggestSize!: number;

  suggestAuto = false;

  mounted() {
    this.suggestAuto = this.realSuggestWidth === 0 && this.realSuggestHeight === 0;
  }
}
