import plist from '../plists/icons-mobile-template.plist';

export interface BackgroundRenderOptions {
  src: string;
  offsetX: number;
  offsetY: number;
  width: number;
  height: number;
}

export interface FloorConfig {
  src?: string;
  iconName: string;
  icon?: BackgroundRenderOptions;
  hoverIcon?: BackgroundRenderOptions;
  name: string;
  order: number
  isHidden?: boolean;
}

export interface FloorModule {
  type: number;
  config: FloorConfig;
  template: any;
  editor: any;
}

export function createFloorConfig(config: FloorConfig): FloorConfig {
  return {
    ...config,
    src: plist.src,
    icon: plist.patches.find(p => p.name === `${config.iconName}gray`) as any,
    hoverIcon: plist.patches.find(p => p.name === `${config.iconName}orange`) as any,
  };
}
