
import {Component, Mixins} from 'vue-property-decorator';
import {TemplateExtra} from '../module';

@Component({
  name: 'template-10'
})
export default class Template extends Mixins(TemplateExtra) {
  announcement = [];

  init() {
    this.data.onChange.subscribe(block => {
      const announcement:any = block.list || this.data.blockList || [];
      if (announcement[0]) this.announcement = JSON.parse(JSON.stringify(announcement[0].block_value))
      else this.announcement = []
    });
  }
}
