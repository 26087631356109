
import {Component, Mixins} from 'vue-property-decorator';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {TemplateExtra} from '../module';
import {SwiperProxy} from '../../services/swiper';
import {FloorMenuItem} from '../../services/floor-menu-item';
import Placeholder from './placeholder.vue';

@Component({
  name: 'template-1',
  components: {
    Swiper,
    SwiperSlide,
    Placeholder,
  },
})
export default class Template extends Mixins(TemplateExtra) {
  slideHeight = '';
  slides: FloorMenuItem[] = [];
  swiperProxy: SwiperProxy = null as any;

  init() {
    this.swiperProxy = SwiperProxy.create({
      instance: this,
      key: this.data.key,
      refKey: 'swiper',
      onReset: () => {
        this.slideHeight = `${400 / 750 * this.ref.clientWidth}px`;
      },
    });
    this.data.onChange.subscribe(block => {
      let list = block.list || this.data.blockList || [];

      // 判断第一个是否是旧版本数据
      const first = list[0];
      if (first?.block_type === 'IMAGELIST') {
        list = first.block_value || [];
      }

      this.slides = list.map(s => FloorMenuItem.createImage(s));
    });
  }

  destroyed() {
    this.swiperProxy?.breakListen();
  }
}
