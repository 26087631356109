
import {Component, PropSync, Vue} from 'vue-property-decorator';

@Component({
  name: 'extra-input'
})
export default class ExtraInput extends Vue {
  @PropSync('title') realTitle!: string;
  @PropSync('placeholder', {default: '请输入'}) realPlaceholder!: string;
  @PropSync('value') realValue!: string;
  @PropSync('errorText') realErrorText!: string;
}
