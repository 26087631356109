import {BehaviorSubject} from 'rxjs';
import {getAlbumTypes, listAlbumPicture} from '@/combo-floor/renovation/api';
import {generateKey} from '@/combo-floor/renovation/services/key';
import {api} from '@/../ui-domain';

export class AlbumImageProxy {
  key = generateKey();
  isUploader = false;
  url = '';
  desc = '';

  constructor(image?: any, private onUpload?: (images: AlbumImageProxy[]) => void) {
    if (image) {
      this.url = image.img_url;
      this.desc = image.img_desc;
    } else {
      this.isUploader = true;
    }
  }

  upload(uploadedFiles: any[]) {
    this.onUpload && this.onUpload(
      uploadedFiles.map(file => {
        return new AlbumImageProxy({
          img_url: file.url,
          img_desc: file.name,
        });
      })
    );
  }
}

export class Album {
  id = 0;
  name = '';
  desc = '';
  sn = '';
  sort = 0;
  images: AlbumImageProxy[] = [];

  pageIndex = 0;
  pageSize = 40;
  hasNextPage = true;

  constructor(data: any, private local = false) {
    if (data) {
      this.id = data.id;
      this.name = data.category_name;
      this.sn = data.sn;
      this.sort = data.sort;
      this.desc = data.desc;
    }

    if (this.local) {
      const localImage = new AlbumImageProxy(null, images => {
        this.images = [...this.images, ...images];
      });
      this.images.push(localImage);
    }
  }

  load() {
    if (this.local || !this.hasNextPage || $album.loading.getValue()) return Promise.resolve();

    $album.loading.next(true);

    if (this.pageIndex === 0) this.pageIndex = 1;

    return listAlbumPicture({
      page_size: this.pageSize,
      page_no: this.pageIndex,
      sn: this.sn,
      category_id: this.id,
    }).then(resp => {
      $album.loading.next(false);

      this.pageIndex = resp.page_no;
      this.hasNextPage = resp.page_size === resp.data.length;
      this.images = [
        ...this.images,
        ...resp.data.map(image => new AlbumImageProxy(image)),
      ];
    });
  }

  loadNext() {
    if (this.local || !this.hasNextPage || $album.loading.getValue()) return Promise.resolve();

    this.pageIndex += 1;

    return this.load();
  }

  handleScroll(e) {
    console.log(e);
  }
}

export class AlbumService {
  albums = new BehaviorSubject([] as Album[]);
  width = new BehaviorSubject(document.body.clientWidth * 0.8);
  loading = new BehaviorSubject(false);
  uploadUrl = api.base + '/uploader' + 's';
  uploadAccept = 'image/*';

  constructor() {
    this.load();
    this.calcWidth();
    window.addEventListener('resize', () => this.calcWidth(), false);
  }

  private calcWidth() {
    const windowWidth = document.body.clientWidth;
    const maxWidth = windowWidth * 0.8 - 16;
    const allNumber = maxWidth / (148 + 16);
    const beyondNumber = allNumber - Math.floor(allNumber);
    let width = maxWidth - beyondNumber * (148 + 16) + 16;
    if (width < 672) width = 672;
    this.width.next(width);
  }

  load(): Promise<Album[]> {
    return getAlbumTypes().then(resp => {
      const albums = resp.data.map(d => new Album(d)).sort((a1, a2) => a1.sort - a2.sort);
      this.albums.next([
        new Album({
          category_name: '本地'
        }, true),
        ...albums,
      ]);
      return this.albums.getValue();
    });
  }

  selectImage!: () => Promise<string>;
  viewImages!: (urls: string[], currentIndex?: number) => void;
}

export const $album = new AlbumService();
